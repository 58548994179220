<template>
  <div>
    <div v-if="isshowgrid">
      <va-card title="Location List">
        <div class="row">
          <div class="flex md6 mt-2">
            <va-input class="va-input-search-container"
              :value="term"
              placeholder="search"
              @input="search"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex md6 text-right">
            <va-button
              icon="fa fa-plus"
              class="my-0"
              @click.prevent="add()">
              Create
            </va-button>
          </div>
        </div>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"
          style="max-height: 90vh;">
          <template slot="actions" slot-scope="props">
            <va-button
              flat
              small
              color="gray"
              icon="fa fa-pencil"
              @click="edit(props.rowData)"
              class="ma-0">
            </va-button>
            <va-button
              flat
              small
              color="red"
              icon="fa fa-remove"
              @click="remove(props.rowData)"
              class="ma-0 ml-2">
            </va-button>
          </template>

        </va-data-table>
        <va-modal
          v-model="showRemoveModal"
          title="Delete Location"
          size='small'
          :message="msg"
          okText="Confirm"
          cancelText="Cancel"
          @ok="deletelocation(entity)"
          @cancel.prevent="cancel()">
        </va-modal>
      </va-card>
    </div>
    <div v-if="isshowForm">
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click.prevent="getAllLocation()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Location Name</span>
            <va-input
              v-model.trim="name"
              type="text"
              placeholder="Enter Location Name"
              :error="!!LocationNameErrors.length"
              :error-messages="LocationNameErrors"
            />
            <span class="va-form-label va-form-required-label">Description</span>
            <va-input
              v-model.trim="description"
              type="text"
              placeholder="Enter Description"
              :error="!!DescriptionErrors.length"
              :error-messages="DescriptionErrors"
            />
            <span class="va-form-label va-form-required-label">Duration</span>
            <va-input
              v-model.trim="duration"
              type="text"
              placeholder="e.g: 0-9 km"
              :error="!!DurationErrors.length"
              :error-messages="DurationErrors"
            />
            <div>
              <span v-if="isCreate" class="va-form-label va-form-required-label">Image (514 X 394)</span>
              <span v-if="isUpdate" class="va-form-label">Image (500 X 394)</span>
              <va-file-upload
                class="mt-2 ml-1"
                @input="CheckFile('horizontal')"
                v-model="image_file"
                type="gallery" 
              />
              <label class="ml-1" v-if="ImageUrlShown">{{existing_image_name}}</label>
              <div class="ml-1" v-if="ImageUrlShown"><img :src=image_url height="250px" width="250px" /></div>
            </div>
            <div class="mt-2">
              <span class="va-form-label">Upload Video</span>
              <div>
                <input
                  type="file"
                  style="display: none;"
                  ref="location_video"
                  accept=".mp4"
                  id="location_video"
                  class="mt-2"
                  v-on:change="validateVideo($event)"
                />              
              </div>
              <va-button
                type="submit"
                class="mt-2 ml-1"
                @click.prevent="$refs.location_video.click()">
                Upload Video
              </va-button>
              <label class="ml-4">{{ video_dis_name }}</label>
            </div>
            <video 
              class="ml-2" 
              v-if="!oldUpload"
              :src="instant_video" 
              width="520" 
              height="440" 
              style="display: none; margin-top: -10px;" 
              controls 
              autoplay 
            />
            <video 
              lass="ml-2" 
              v-if="oldUpload"
              id="myVideo" 
              width="520" 
              height="440" 
              style="margin-top: -10px;" 
              autoplay 
              controls
              :src=videoUrl 
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="getAllLocation()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="locationProcess('create')">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="locationProcess('update')">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>

  import Vue from 'vue'
  import vueResource from 'vue-resource'
  import { search } from '../../i18n/search.js'
  import config from '../../i18n/en.json'
  import REGEX from '../../i18n/regex_pattern'
  Vue.use(vueResource)

  export default {
    name: 'app_setting',
    data () {
      return {
        term : null,
        perPage: '10',
        perPageOptions: ['4', '6', '10', '20'],
        isCreate : false,
        isUpdate : false,
        isshowgrid : true,
        isshowForm : false,
        showRemoveModal : false,
        msg: '',
        name : '',
        LocationNameErrors : [],
        description : '',
        DescriptionErrors : [],
        duration : '',
        DurationErrors : [],
        location_list : [],
        image_file : [],
        ImageUrlShown : false,
        location_video : [],
        video_dis_name : '',
        existing_image_name : '',
        oldUpload : false,
        videoUrl: '',
        instant_video : '',
        location_meta_id : ''
      }
    },
    computed: {
      formReady () {
        return !this.LocationNameErrors.length && !this.DescriptionErrors.length && !this.DurationErrors.length
      },
      fields () {
        return [
          {
            name: 'name',
            title: 'Location Name',
          },
          {
            name: 'duration',
            title: 'Duration',
          },
          {
            name: '__slot:actions',
            dataClass: 'text-right',
          },
        ]
      },
      filteredData () {
        return search(this.term, this.location_list)
      },
    },
    created () {
      this.getAllLocation()
    },
    methods: {
      CheckFile(type) {
        if(this.image_file[0]){
          this.ImageUrlShown = false;
          var image_file = this.image_file[0]
          var file_name = image_file.name.split('.').pop()
          if (file_name.toLowerCase() === 'png' || file_name.toLowerCase() === 'jpg' || file_name.toLowerCase() === 'jpeg'){
            const img = new Image()
            let width = '514'
            let height = '394'
            const reader = new FileReader()
            reader.readAsDataURL(image_file)
            reader.onload = evt => {
              img.onload = () => {
                if (img.width == width && img.height == height) {
                  this.image_file[0] = image_file
                }else{
                  this.image_file = ''
                  return Vue.notify({ text: 'Please check the image size', type: 'error' })
                }
              }
              img.src = evt.target.result
            }
          }else{
            this.image_file = ''
            return Vue.notify({ text: 'Please check the image Format', type: 'error' })
          }
        }
      },
      validateVideo (eve) {
        var video = eve.target.files[0]
        var size = eve.target.size
        if ((size / (1024 * 1024)) <= 200) {
          this.video_dis_name = video.name
          this.location_video = video
          let blobURL = URL.createObjectURL(video);
          this.instant_video = blobURL
          document.querySelector("video").style.display = 'block';
          document.querySelector("video").src = blobURL;
          return true
        } else {
          alert('please upload video less than 200 MB')
          this.video_dis_name = ''
          return false
        }
      },
      deleteFiles (type) {
        if(type == 'image'){
          this.ImageUrlShown = false;
        }else{
          this.video_dis_name = '';
          this.oldUpload = false;
          this.newUpload = false;
        }
      },
      getAllLocation () {
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.get(config.menu.host + '/location_meta').then(response => {
          loader.hide()
          this.location_list = response.body
          this.isshowgrid = true
          this.isshowForm = false
          this.oldUpload = false
        }, err => {
          loader.hide()
          if (error && error.body) Vue.notify({ text: error.body, type: 'error' });
        });
      },
      locationProcess (type) {
        this.LocationNameErrors = this.name ? [] : ['Location Name is required'];
        this.DescriptionErrors = this.description ? [] : ['Description is required'];
        this.DurationErrors = this.duration ? [] : ['Duration is required'];

        if(!this.formReady) {
          return
        };
        if(!this.image_file[0] && type == 'create') {
          return Vue.notify({text:'Please Upload the Image', type: 'error'});
        };

        var payload = new FormData()
        payload.append('name', this.name);
        payload.append('description', this.description);
        payload.append('duration', this.duration);
        if(this.image_file.length != 0) payload.append('image', this.image_file[0]);
        if(this.location_video && this.location_video.length != 0) payload.append('video', this.location_video);

        if(type == 'create') {
          var requestUrl = this.$http.post(config.menu.host + '/location_meta', payload) 
        }else{
          var requestUrl = this.$http.put(config.menu.host + '/location_meta/' + this.location_meta_id, payload)
        }

        const loader = Vue.$loading.show({ width: 40, height: 40 });
        requestUrl.then(responseData => {
          loader.hide();
          Vue.notify({ text: responseData.body, type: 'success' });
          this.getAllLocation();
        }, err => {
          loader.hide();
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' });
            this.isshowForm = true
          };
        });
      },
      add () {
        this.title = 'Create Location';
        this.isshowgrid = false;
        this.isshowForm = true;
        this.isCreate = true;
        this.isUpdate = false;
        this.errorClear();
        this.name = '';
        this.description = '';
        this.duration = '';
        this.video_dis_name = '';
        this.existing_image_name = '';
      },
      errorClear () {
        this.LocationNameErrors = [];
        this.DescriptionErrors = [];
        this.DurationErrors = [];
        this.oldUpload = false;
        this.videoUrl = '';
        this.ImageUrlShown = false;
        this.image_file = [];
        this.location_video = [];
      },
      edit (row) {
        this.title = 'Update Location';
        this.isshowgrid = false;
        this.isshowForm = true;
        this.isCreate = false;
        this.isUpdate = true;
        this.errorClear();
        this.name = row.name;
        this.location_meta_id = row.location_meta_id;
        this.description = row.description;
        this.duration = row.duration;
        this.ImageUrlShown = (row.image) ? true : false;
        this.existing_image_name = (row.image) ? row.image.split('/').pop() : '';        
        if(this.ImageUrlShown) this.image_url = row.image;
        if(row.video) {
          this.oldUpload = true
          this.videoUrl = row.video;
          this.video_dis_name = row.video.split('/').pop();
          document.getElementById("myVideo").src = row.video;
        }
      },
      remove (row) {
        this.msg = 'Are you sure to delete the Location ' + row.name + ' ?';
        this.entity = row;
        this.showRemoveModal = true;
      },
      cancel () {
        this.showRemoveModal = false;
      },
      deletelocation (data) {
        this.$http.delete(config.menu.host + '/location_meta/' + data.location_meta_id).then(resp => {
          Vue.notify({ text: resp.body, type: 'success' });
          this.getAllLocation();
        }, err => {
          if (err && err.body) Vue.notify({ text: err.body, type: 'error' });
        });
      },
      search: function (term) {
        this.term = term
      },
    } 
  }

</script>

<style lang="scss">
</style>